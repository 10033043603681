import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostBlock from '../components/post-block'
import Pager from '../components/pager'

import { RefTagger } from 'react-reftagger';

const PostIndex = ({ data, location, pageContext }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allFile.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />
      <RefTagger bibleVersion={'ESV'} />
      <div className="mt-8"></div>
      
      {posts.map(post => {
        return (
          <div className="mb-10" key={post.childMarkdownRemark.frontmatter.postId}>
            <PostBlock post={post.childMarkdownRemark} site={data.site} className="mt-10"/>
            <hr className="center-diamond"/>
          </div>
        )
      })}
      
      <Pager currentPage={pageContext.currentPage} totalPages={pageContext.numPages} pageLinkPrefix="/index" pageOneLink="/"/>
    </Layout>
  )
}

export default PostIndex

export const pageQuery = graphql`
  query PostIndex(
    $limit: Int!
    $skip: Int!
  ) {
    site {
      siteMetadata {
        title
        audioServerUrl
        resourceServerUrl
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "posts"} }, sort: { fields: [childrenMarkdownRemark___frontmatter___postDate], order:DESC}, limit: $limit, skip: $skip) {
      nodes {
        childMarkdownRemark {
          excerpt
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            lessonDate(formatString: "MMMM DD, YYYY")
            title
            description
            audio
            video
            postType
            postId
            passages
            resources
            series {
              frontmatter {
                name
                seriesId
                coverImage {
                  childImageSharp {
                    gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                  }
                }
              }
            }
            subSeries {
              frontmatter {
                name
                seriesId
                coverImage {
                  childImageSharp {
                    gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                  }
                }
              }
            }
            venue {
              frontmatter {
                name
                venueId
              }
            }
            presenters {
              frontmatter {
                name
                presenterId
              }
            }
            topics {
              frontmatter {
                name
                type
                topicId
              }
            }
            relatedPosts {
              frontmatter {
                postId
                date(formatString: "MMMM DD, YYYY")
                lessonDate(formatString: "MMMM DD, YYYY")
                title
                description
                postType
                audio
                video
                passages
                series {
                  frontmatter {
                    name
                    seriesId
                    coverImage {
                      childImageSharp {
                        gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                      }
                    }
                  }
                }
                subSeries {
                  frontmatter {
                    name
                    seriesId
                    coverImage {
                      childImageSharp {
                        gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                      }
                    }
                  }
                }
                venue {
                  frontmatter {
                    name
                    venueId
                  }
                }
                presenters {
                  frontmatter {
                    name
                    presenterId
                  }
                }
              }
            }            
          }
        }
      }
    }
  }
`
